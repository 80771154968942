/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.6.11/dist/vue.min.js
 * - /npm/vue-router@3.1.6/dist/vue-router.min.js
 * - /npm/axios@0.19.2/dist/axios.min.js
 * - /gh/suconghou/assets@c7e2e06/fastload.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
